<template>
    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10">
        <v-card-title class="headline"> Set Up </v-card-title>

        <v-card-text class="text-left">
            For setting up the standalone for Evolve Analytics, you will require
            the id for our si_fw_generator-vuejs framework:
            <br />
            <br />
            Creative ID: <strong>{{ data.creative_id }}</strong>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'SetUp',

    props: ['id', 'data']
};
</script>

<style scoped lang="scss">
.creative__api {
    &-container {
        border-radius: 5px;
        margin: 20px auto;
        overflow: hidden;

        &__text {
            font-size: 12px;
            text-align: left;
            font-family: Consolas;
            color: #d5d5d7;
            letter-spacing: 1px;
            padding: 15px;
            word-wrap: break-word;
        }
    }
}
</style>
