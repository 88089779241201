<template>
    <v-container class="analytics mb-6">
        <v-card v-if="loaded">
            <v-card-text>
                <v-toolbar flat>
                    <v-toolbar-title>{{ analytics.title }}</v-toolbar-title>
                    <v-spacer />
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltipTrigger }">
                            <v-hover v-slot="{ hover }">
                                <v-btn
                                    :color="hover ? 'primary' : ''"
                                    :href="getReportUrl()"
                                    class="ma-2"
                                    download
                                    fab
                                    outlined
                                    target="_blank"
                                    x-small
                                    v-on="{ ...tooltipTrigger }"
                                >
                                    <v-icon>fa-file-excel</v-icon>
                                </v-btn>
                            </v-hover>
                        </template>
                        <span>Export Report</span>
                    </v-tooltip>
                </v-toolbar>

                <v-row class="mt-4">
                    <v-col cols="12" lg="8" md="8" xl="10">
                        <p class="text-left white--text">
                            by
                            <span class="blue--text">{{
                                analytics.user.name
                                    ? analytics.user.name
                                    : analytics.user.email
                            }}</span
                            ><br />
                            Created at:
                            <span class="value">
                                {{ analytics.created_at }}</span
                            >
                        </p>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" xl="2">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="dates"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dates"
                                    label="Date range"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dates" range scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="modal = false"
                                    >Cancel
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="onRangeChange(dates)"
                                    >OK
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                        <p class="process text-right text-overline white--text">
                            Last Process at:<br />
                            <span class="blue--text">{{
                                analytics.processed_at
                            }}</span
                            ><br />
                        </p>
                    </v-col>
                </v-row>

                <v-divider />

                <v-row>
                    <v-col cols="12" md="3">
                        <v-list ref="main" class="text-left" shaped>
                            <v-subheader>INSIGHTS</v-subheader>
                            <v-list-item-group v-model="selection">
                                <v-list-item v-for="set in sets" :key="set">
                                    <v-list-item-title
                                        :class="{
                                            'pt-4':
                                                set === 'vcr' &&
                                                !notObject(
                                                    analytics.insights[set]
                                                )
                                        }"
                                    >
                                        <v-tooltip
                                            :disabled="set !== 'loads'"
                                            right
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on: tooltipTrigger
                                                }"
                                            >
                                                <span
                                                    v-on="{ ...tooltipTrigger }"
                                                >
                                                    <span>{{
                                                        set !== 'timespents'
                                                            ? toTitle(set)
                                                            : 'Avg Timespent'
                                                    }}</span>
                                                    <span
                                                        v-if="
                                                            analytics.insights[
                                                                set
                                                            ] !== undefined &&
                                                                notObject(
                                                                    analytics
                                                                        .insights[
                                                                        set
                                                                    ]
                                                                )
                                                        "
                                                        class="value"
                                                    >
                                                        {{
                                                            ' - ' +
                                                                analytics
                                                                    .insights[
                                                                    set
                                                                ]
                                                        }}<span
                                                            v-if="
                                                                set.length <= 3
                                                            "
                                                            >%</span
                                                        >
                                                    </span>
                                                    <span
                                                        v-if="
                                                            set === 'timespents'
                                                        "
                                                        class="value"
                                                    >
                                                        {{
                                                            ' - ' +
                                                                analytics
                                                                    .insights
                                                                    .avg_timespent
                                                        }}s
                                                    </span>
                                                </span>
                                            </template>
                                            <span
                                                >AVG Load time
                                                {{
                                                    analytics.insights
                                                        .load_times.average
                                                }}s</span
                                            >
                                        </v-tooltip>
                                        <ul
                                            v-if="
                                                set === 'vcr' &&
                                                    !notObject(
                                                        analytics.insights[set]
                                                    )
                                            "
                                            class="nested-list"
                                        >
                                            <li
                                                v-for="(vcr, index) in analytics
                                                    .insights.vcr"
                                                :key="index"
                                            >
                                                <v-tooltip right>
                                                    <template
                                                        v-slot:activator="{
                                                            on: tooltipTrigger
                                                        }"
                                                    >
                                                        <span
                                                            class="label"
                                                            v-on="{
                                                                ...tooltipTrigger
                                                            }"
                                                            >{{ index }} >
                                                        </span>
                                                    </template>
                                                    <span>{{ index }}</span>
                                                </v-tooltip>
                                                <span class="value"
                                                    >{{ vcr }}%</span
                                                >
                                            </li>
                                            <template
                                                v-if="
                                                    !notEmpty(
                                                        analytics.insights.vcr
                                                    )
                                                "
                                            >
                                                <span class="no-data">{{
                                                    'No Data'
                                                }}</span>
                                            </template>
                                        </ul>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                    <v-col class="mb-10" cols="12" md="9">
                        <line-chart
                            v-if="mainChart"
                            :data="data.main"
                            :title="toTitle(sets[selection])"
                        ></line-chart>
                    </v-col>
                </v-row>

                <v-divider class="mb-10" />

                <v-row class="mb-4">
                    <v-col v-if="notEmpty(analytics.insights.domains)">
                        <pie-chart
                            :data="data.domains"
                            :options="data.pieChartOptions"
                            :title="'Domains'"
                        ></pie-chart>
                    </v-col>
                    <v-col v-if="notEmpty(analytics.insights.browsers)">
                        <pie-chart
                            :data="data.browsers"
                            :options="data.pieChartOptions"
                            :title="'Browsers'"
                        ></pie-chart>
                    </v-col>
                </v-row>
                <v-row class="mb-4">
                    <v-col
                        v-if="
                            notEmpty(analytics.insights.devices) &&
                                (analytics.insights.devices.mobile > 0 ||
                                    analytics.insights.devices.desktop > 0 ||
                                    analytics.insights.devices.tablet > 0)
                        "
                    >
                        <pie-chart
                            :data="data.devices"
                            :options="data.pieChartOptions"
                            :title="'Devices'"
                        ></pie-chart>
                    </v-col>
                    <v-col v-if="notEmpty(analytics.insights.geographics)">
                        <pie-chart
                            :data="data.geographics"
                            :options="data.pieChartOptions"
                            :title="'Geographics'"
                        ></pie-chart>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-title>
                                Events
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    hide-details
                                    label="Search"
                                    single-line
                                ></v-text-field>
                            </v-card-title>

                            <v-data-table
                                :headers="headers"
                                :items="events"
                                :search="search"
                            ></v-data-table>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-title>
                                Engagements
                            </v-card-title>

                            <v-data-table
                                :headers="headers"
                                :items="engagementsBreakdown"
                            ></v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import NotificationService from '@/services/notification.service';
import _ from 'lodash';
import Constants from '@/constants';
import moment from 'moment';
import PieChart from '@/components/PieChart.vue';
// import BarChart from '@/components/BarChart.vue';
import LineChart from '@/components/LineChart.vue';
import LoadingService from '@/services/loading.service';
import { Logger } from '@/services/logger.service';

export default {
    name: 'Analytics',

    data: () => ({
        loaded: false,
        dates: [],
        modal: false,
        selection: 0,
        mainChart: false,
        sets: [
            'loads',
            'clicks',
            'ctr',
            'engagements',
            'er',
            'interactions',
            'ir',
            'vcr',
            'timespents'
        ],
        data: {
            main: {
                labels: [],
                datasets: []
            },
            pieChartOptions: {
                responsive: false,
                maintainAspectRatio: true,
                plugins: {
                    title: {
                        display: true
                    },
                    legend: {
                        display: true,
                        position: 'bottom'
                    }
                }
            },
            geographics: {
                labels: [],
                datasets: [
                    {
                        label: 'Geographics',
                        backgroundColor: [],
                        data: [],
                        borderWidth: 1
                    }
                ]
            },
            browsers: {
                labels: [],
                datasets: [
                    {
                        label: 'Browsers',
                        backgroundColor: [],
                        data: [],
                        borderWidth: 1
                    }
                ]
            },
            domains: {
                labels: [],
                datasets: [
                    {
                        label: 'Domains',
                        backgroundColor: [],
                        data: [],
                        borderWidth: 1
                    }
                ]
            },
            devices: {
                labels: [],
                datasets: [
                    {
                        label: 'Devices',
                        backgroundColor: [],
                        data: [],
                        borderWidth: 1
                    }
                ]
            }
        },
        search: '',
        headers: [
            {
                text: 'Event',
                align: 'start',
                sortable: true,
                value: 'event'
            },
            {
                text: 'Page',
                align: 'start',
                sortable: true,
                value: 'page'
            },
            {
                text: 'Attribute',
                align: 'start',
                sortable: true,
                value: 'attribute'
            },
            {
                text: 'Component Id',
                align: 'start',
                sortable: true,
                value: 'component_id'
            },
            {
                text: 'Total',
                align: 'start',
                sortable: true,
                value: 'totals'
            }
        ],
        events: [],
        engagementsBreakdown: []
    }),

    computed: {
        isLoading() {
            return this.$store.getters['standalone/analytics'] === 'loading';
        },

        analytics() {
            return this.$store.getters['standalone/analytics'];
        },

        dateRangeText() {
            return this.dates.join(' ~ ');
        }
    },

    watch: {
        selection() {
            this.setMainChartData();
        },

        '$route.params'() {
            window.location.reload();
        }
    },

    mounted() {
        const range = {
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month')
        };

        LoadingService.show();

        if (
            (this.$route.params.startDate &&
                !moment(
                    this.$route.params.startDate,
                    'YYYY-MM-DD',
                    true
                ).isValid()) ||
            (this.$route.params.endDate &&
                !moment(
                    this.$route.params.endDate,
                    'YYYY-MM-DD',
                    true
                ).isValid())
        ) {
            NotificationService.show(
                'Incorrect format for start date or end date'
            );
            this.$router.go(-1);
            return;
        }

        const params = {
            id: Number(this.$route.params.id),
            startDate:
                this.$route.params.startDate ||
                range.startDate.format('YYYY-MM-DD'),
            endDate:
                this.$route.params.endDate || range.endDate.format('YYYY-MM-DD')
        };

        this.dates[0] = params.startDate;
        this.dates[1] = params.endDate;

        Logger('info', 'Analytics').log('Getting data from dates:', {
            startDate: this.dates[0],
            endDate: this.dates[1]
        })();

        this.$store.dispatch('standalone/reset').then(() => {
            this.$store.dispatch('standalone/getAnalytics', params).then(() => {
                this.processData();
            });
        });
    },

    methods: {
        onRangeChange(dates) {
            this.$refs.dialog.save(dates);
            const url =
                '/standalone/' +
                this.$route.params.id +
                '/analytics/' +
                dates[0] +
                '/' +
                dates[1];
            this.$router.push({
                path: url,
                force: true
            });
        },

        getReportUrl() {
            let url =
                Constants.APP_URL !== ''
                    ? Constants.APP_URL
                    : window.location.origin;
            url += '/api/v1/i/standalones/' + this.$route.params.id;
            url += '/analytics/' + this.dates[0] + '/' + this.dates[1];
            url += '/export';

            return url + '?token=' + this.$store.getters['auth/token'];
        },

        notEmpty(data) {
            return !_.isEmpty(data);
        },

        notObject(data) {
            return !_.isObject(data);
        },

        generateRandomColor() {
            return '#000000'.replace(/0/g, function() {
                return (~~(Math.random() * 16)).toString(16);
            });
        },

        toTitle(label) {
            const title = label.replace('_', ' ');

            if (title.length <= 3) {
                return title.toUpperCase();
            }

            return _.capitalize(title);
        },

        setMainChartData() {
            this.mainChart = false;
            this.data.main.labels = this.analytics.insights.summary.chart_dates;
            this.data.main.datasets = [];

            _.forEach(
                this.analytics.insights.summary.chart_info,
                (value, key) => {
                    if (this.sets[this.selection] === key) {
                        if (_.every(value, _.isNumber)) {
                            const color = this.generateRandomColor();

                            this.data.main.datasets.push({
                                label: this.toTitle(key),
                                data: value,
                                backgroundColor: color,
                                borderColor: color,
                                pointBackgroundColor: color,
                                borderWidth: 1,
                                pointRadius: 7,
                                pointHoverRadius: 7,
                                pointHoverBorderWidth: 3,
                                pointStyle: 'rect'
                            });
                        } else {
                            this.data.main.datasets = [];

                            _.forEach(value, (v, k) => {
                                const color = this.generateRandomColor();

                                this.data.main.datasets.push({
                                    label: k,
                                    data: v,
                                    backgroundColor: color,
                                    borderColor: color,
                                    pointBackgroundColor: color,
                                    borderWidth: 1,
                                    pointRadius: 7,
                                    pointHoverRadius: 7,
                                    pointHoverBorderWidth: 3,
                                    pointStyle: 'rect'
                                });
                            });
                        }
                    }
                }
            );

            this.$nextTick(() => {
                this.mainChart = true;
            });
        },

        processData() {
            this.setMainChartData();
            this.setEvents();
            this.engagementsBreakdown = this.analytics.insights.engagements_breakdown;

            _.forEach(this.analytics.insights.geographics, (v, k) => {
                this.data.geographics.labels.push(k);
                this.data.geographics.datasets[0].data.push(v);
                this.data.geographics.datasets[0].backgroundColor.push(
                    this.generateRandomColor()
                );
            });

            _.forEach(this.analytics.insights.browsers, (v, k) => {
                this.data.browsers.labels.push(k);
                this.data.browsers.datasets[0].data.push(v);
                this.data.browsers.datasets[0].backgroundColor.push(
                    this.generateRandomColor()
                );
            });

            _.forEach(this.analytics.insights.domains, (v, k) => {
                this.data.domains.labels.push(k);
                this.data.domains.datasets[0].data.push(v);
                this.data.domains.datasets[0].backgroundColor.push(
                    this.generateRandomColor()
                );
            });

            _.forEach(this.analytics.insights.devices, (v, k) => {
                this.data.devices.labels.push(k);
                this.data.devices.datasets[0].data.push(v);
                this.data.devices.datasets[0].backgroundColor.push(
                    this.generateRandomColor()
                );
            });

            this.$nextTick(() => {
                this.loaded = true;

                setTimeout(() => {
                    LoadingService.hide();
                });
            });
        },

        setEvents() {
            _.forEach(this.analytics.insights.events, (data, event) => {
                _.forEach(data.breakdown, item => {
                    const generated = {
                        event
                    };
                    _.forEach(item, (v, k) => {
                        generated[k] = v;
                    });
                    this.events.push(generated);
                });
            });
        }
    },

    components: {
        LineChart,
        // BarChart,
        PieChart
    }
};
</script>

<style lang="scss">
.analytics {
    .value {
        color: #cccccc;
        margin-left: 5px;
    }

    .process {
        font-size: 10px !important;
        line-height: 15px;
    }

    .no-data {
        font-family: 'Courier New', monospace;
        font-size: 12px;
    }

    .pie-chart,
    .bar-chart,
    .line-chart {
        text-align: center;
        width: 100%;

        canvas {
            margin: 0px auto;
        }
    }

    .nested-list {
        box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%),
            0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);
        display: block;
        padding: 8px 0;
        position: static;
        transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

        li {
            align-items: center;
            display: flex;
            flex: 1 1 100%;
            letter-spacing: normal;
            list-style: none;
            min-height: 48px;
            outline: none;
            padding: 0 16px;
            position: relative;
            text-decoration: none;
        }
    }
}
</style>
