<template>
    <div class="creative creative--standalone">
        <f-creative-container
            v-if="ready"
            :id="id"
            :tabs="tabs"
            :data="data"
        ></f-creative-container>
    </div>
</template>

<script>
import CreativeContainer from '@/views/creatives/components/CreativeContainer';
import Edit from '@/views/creatives/standalone/components/Edit';
import SetUp from '@/views/creatives/standalone/components/SetUp';
import Analytics from '@/views/creatives/standalone/components/Analytics';

export default {
    name: 'Standalone',

    data: () => ({
        id: null,
        tabs: [
            {
                name: 'New',
                key: 'edit',
                icon: 'fa-plus-square',
                component: Edit,
                disabled: false
            },
            {
                name: 'Set Up',
                key: 'setUp',
                icon: 'fa-bolt',
                component: SetUp,
                disabled: true
            },
            {
                name: 'Analytics',
                key: 'analytics',
                icon: 'fa-chart-line',
                component: Analytics,
                disabled: true
            }
        ],
        ready: false
    }),

    computed: {
        data() {
            return this.id !== null
                ? this.$store.getters['standalone/data']
                : {};
        },

        loading() {
            return this.$store.getters['standalone/status'];
        }
    },

    watch: {
        '$route.params.id'() {
            this.init();
        }
    },

    mounted() {
        this.init();
    },

    destroyed() {
        this.$store.dispatch('standalone/reset').catch(() => {});
    },

    methods: {
        setInitialData() {
            const id =
                this.$route.params.id === 'new' ? null : this.$route.params.id;

            this.id = id;
            this.tabs[0].name = id === null ? 'New' : 'Edit';
            this.tabs[0].icon = id === null ? 'fa-plus-square' : 'fa-edit';
            this.tabs[1].disabled = id === null;
            this.tabs[2].disabled = id === null;
            this.ready = false;
        },

        init() {
            this.setInitialData();

            setTimeout(() => {
                if (this.id !== null) {
                    this.$store
                        .dispatch('standalone/reset')
                        .then(() => {
                            this.$store
                                .dispatch('standalone/get', this.id)
                                .then(() => {
                                    this.ready = true;
                                })
                                .catch(() => {});
                        })
                        .catch(() => {});
                } else {
                    this.ready = true;
                }
            });
        }
    },

    components: {
        FCreativeContainer: CreativeContainer
    }
};
</script>

<style scoped lang="scss"></style>
